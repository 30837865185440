// UI框架
import React, { Component } from 'react';
// UI组件
import { Tooltip, message, Modal } from 'antd';
import { Icon, PtzControl } from 'libs';
// 业务组件
import plugin from 'plugin';
import { bindLifecycle } from 'utils/bindLifecycle';
import { LangConsumer } from 'utils/LangProvider';
import { clone, isZeroArray } from '../../../../common';
import _ from 'lodash';
import WebApp from '../../../../webapp';
// 样式
import './index.less';

const _name_ = 'TrafficIllegalRegionConfigurationPlugin';

class TrafficIllegalRegionConfigurationPluginComponent extends React.Component {
    /** 用来存放组件内状态 */
    constructor (props) {
        super(props);
        this.ComponentName = _name_;
        this.videoRef = React.createRef();
        this.state = {
            containerID: 0, //图形容器ID
            hidePluginFlag: false, //控件是否隐藏
            mainIds: [], //主图形ID
            newAreaData: {}, //新增区域数据
            ptzControlVisible: false, //控制云台显示
            stopPtzFastPosFlag: false //控制云台快速定位按钮是否取消
        };
    }

    componentDidMount () {
        this.open();
    }

    componentDidActivate () {
        this.open();
    }

    componentWillUnmount () {
        this.leave();
    }

    componentWillUnactivate () {
        this.leave();
    }
    /**
     * 打开视频
     * @param {Number} stream 码流
     * @param {Number} channel 通道
     * @param {Number} viewIndex 窗口号
     */
    open = (stream = 1, channel = 0, viewIndex = 0) => {
        plugin.open(stream, channel, viewIndex);
    };
    /**
     * 控件覆盖
     */
    cover = () => {
        plugin.cover(this.videoRef.current);
    };
    /**
     * 视频关闭
     */
    close = () => {
        plugin.close();
    };
    /**
     * 视频隐藏
     */
    hide = () => {
        plugin.hide();
    };

    /**
     * 离开页面触发
     * 1. 清空规则；2.销毁容器；3.取消监听；4.隐藏插件；5.停止拉流
     */
    leave = (channel) => {
        let { containerID } = this.state;
        plugin.DeactivateReactAreaSelection();
        // 清空规则，防止在预览或者其他页面中重新绘制
        plugin.DeleteAllVideoAnalyseShape(containerID);
        // 销毁容器
        plugin.ReleaseVideoAnalyseContainer(containerID);
        plugin.addEvent('VideoAnalyzeConfig', null); //停止事件监听
        plugin.hide();
        plugin.close(channel);
        this.setState({ containerID: 0, stopPtzFastPosFlag: true });
    };
    /**
     * 监听事件
     */
    addPluginEvent = () => {
        let {addAreaData} = this.props;
        plugin.addEvent('pluginInstall', () => {
            setTimeout(() => {
                this.drawArea();
            }, 50);
        }); //登入后控件安装完成
        plugin.addEvent('VideoAnalyzeConfig', (shapeID, data, curState, preState, eventType, eventName, markedName, shapeType, eventParam) => {
            let {$t} = this.props;
            let {containerID, newAreaData} = this.state;
            if (curState === 3) { //绘制完成
                switch (eventName) {
                case 'drawDetectArea':
                    if (preState === 2) { // 新增图形
                        newAreaData.DetectRegion = data;
                        plugin.SetVideoAnalyseContainerTip(containerID, $t('com.DrawingIsCompletedTip'));
                        addAreaData(newAreaData,true); //调用父组件函数
                    } else if (preState === 5 || preState === 4 || preState === 3) { // 修改/选中/移动图形图形
                        this._ResetAreaState();
                        this._SelectCurArea({'data' : data, 'id' : shapeID});
                    }
                    break;
                }
            }
        });
    };
    /**
     * 绘制区域图形
     */
    drawArea = async () => {
        let {curGlobalConfig, sceneType} = this.props;
        let {containerID, mainIds} = this.state;
        if (containerID === 0) {
            if (plugin.isInStalled) {
                await plugin.CreateVideoAnalyseContainer().then((id) => {
                    plugin.EnableVideoAnalyseContainer(id, true);
                    containerID = id;
                    this.setState({ containerID });
                });
            }
        }
        this.clearRule(); //绘制前清理一下
        this.setState({ containerID }, () => {
            mainIds = [];
            if (curGlobalConfig && curGlobalConfig.Detail && curGlobalConfig.Detail.DetectRegions) {
                if (curGlobalConfig.Detail.DetectRegions instanceof Array && curGlobalConfig.Detail.DetectRegions.length > 0) {
                    curGlobalConfig.Detail.DetectRegions.forEach((item, index) => {
                        if (item.DetectRegion.length > 0 && !isZeroArray(item.DetectRegion)
                         && (item.SceneDetectRegionType === undefined || (item.SceneDetectRegionType !== undefined && item.SceneDetectRegionType[0] === sceneType))) {
                            plugin.CreateMainVideoAnalyseShape(containerID, 'drawDetectArea', 'Polygon', item.Name, JSON.stringify({Polygon: item.DetectRegion}), '').then((shapeID) => {
                                mainIds.push(shapeID);
                                plugin.EnableVideoAnalyseShape(containerID, shapeID, true);
                                plugin.ShowVideoAnalyseShape(containerID, shapeID, item.Enable);
                            });
                        }
                    });
                    this.setState({mainIds}, () => {
                        window.setTimeout(() => {
                            this._ResetAreaState();
                            this._SelectCurArea();
                        }, 50);
                    });
                } else {
                    this.setState({mainIds});
                }
            }
        });
    }

    // 清除所有图形
    clearRule = () => {
        let { containerID } = this.state;
        plugin.DeleteAllVideoAnalyseShape(containerID);
    };

    /**
     * 当控件隐藏时，显示视频区默认图片
     * @param [Boolean][bool]  控件是否隐藏
     */
    showVideoDefaultImage = (bool) => {
        this.setState({hidePluginFlag : bool});
    }
    /**
     * 重置区域图形选中状态
     */
    _ResetAreaState = () => {
        let {mainIds, containerID} = this.state;
        for (let i = 0; i < mainIds.length; i++) {
            plugin.SelectVideoAnalyseShape(containerID, mainIds[i], false);
        }
    }
    /**
     * 选中区域图形
     * @param {Object} modifyOrSelectedData {修改或移动图形时的坐标数据, 当前操作的图形id}
     */
    _SelectCurArea = (modifyOrSelectedData) => {
        let {mainIds, containerID} = this.state;
        let {selectRow, modifyOrSelectedAreaData} = this.props;
        if (modifyOrSelectedData) {
            let curIndex = mainIds.indexOf(modifyOrSelectedData.id);
            plugin.SelectVideoAnalyseShape(containerID, mainIds[curIndex], true);
            let areaObj = this.findAreaObj(curIndex);
            areaObj.DetectRegion = modifyOrSelectedData.data;
            modifyOrSelectedAreaData(areaObj);
        } else {
            let areaObj = this.findAreaObj();
            plugin.SelectVideoAnalyseShape(containerID, mainIds[selectRow], true);
            this.setState({newAreaData: areaObj});
        }
    }
    /**
     * 显示区域图形
     * @param {Number} index 区域图形在mainIds中的下标
     * @param {Boolean} enable 是否显示
     */
    _ShowArea = (index, enable) => {
        let {mainIds, containerID} = this.state;
        plugin.ShowVideoAnalyseShape(containerID, mainIds[index], enable);
    }
    /**
     * 添加新区域图形
     * @param {Object} data 新增区域的数据
     */
    drawNewArea = (data) => {
        let {$t} = this.props;
        let {mainIds, containerID} = this.state;
        plugin.CreateMainVideoAnalyseShape(containerID, 'drawDetectArea', 'Polygon', data.Name, '', '').then((shapeID) => {
            mainIds.push(shapeID);
            plugin.SetVideoAnalyseContainerTip(containerID, $t('com.PleaseDrawShapeTip'));
            this.setState({newAreaData : data});
        });
    }
    /**
     * 查找对应区域号的数据对象
     * @param {Number} operatedIndex 当前操作图形的shapeId在mainIds中的下标
     * @return {Object} areaObj 查找到的数据对象
     */
    findAreaObj = (operatedIndex) => {
        let {curGlobalConfig, selectNumber} = this.props;
        let areaObj = {};
        if (operatedIndex !== undefined) {
            areaObj = curGlobalConfig.Detail.DetectRegions[operatedIndex];
        } else {
            for (let i = 0; i < curGlobalConfig.Detail.DetectRegions.length; i++) {
                if (curGlobalConfig.Detail.DetectRegions[i].Number === selectNumber) {
                    areaObj = curGlobalConfig.Detail.DetectRegions[i];
                    break;
                }
            }
        }
        return areaObj;
    }

    /**
     * 重新绘制某一图形
     * @param {Number} selectedRow 需要重绘图形的表格行号
     */
    rePluginDrawArea = (selectedRow) => {
        let {mainIds, containerID} = this.state;
        plugin.RedrawVideoAnalyseShape(containerID, mainIds[selectedRow]);
    }

    // 显示云台
    showPTZControl = () => {
        this.setState({ptzControlVisible: true});
    }
    // 隐藏云台
    hidePTZControl = () => {
        this.setState({ptzControlVisible: false});
    }

    render () {
        let {$t, curPreset, curChannel, curPtzName} = this.props;
        let {hidePluginFlag, ptzControlVisible, stopPtzFastPosFlag} = this.state;
        return (
            <div className={`_${_name_}_`}>

                <div className="video-wrap" ref={this.videoRef}>
                    {
                        hidePluginFlag && <div style = {{textAlign : 'center', marginTop : '30%'}}><Icon type = 'videoDefault'/></div>
                    }
                </div>
                <div className="aitoolsbar-warp">
                    {WebApp.PtzControlChannel.ivs.includes(curChannel) ?
                        <div className='ptz-icon' onClick={this.showPTZControl}>
                            <Tooltip placement='right' title={$t('com.PtzControl')}>
                                <Icon type="ptzControl"/>
                            </Tooltip>
                        </div> : null
                    }
                    <Modal
                        visible={ptzControlVisible}
                        getContainer={() => {
                            return document.querySelector(`._${_name_}_`);
                        }}
                        width={300}
                        footer={null}
                        maskClosable={false}
                        mask={true}
                        onCancel={this.hidePTZControl}
                    >
                        <PtzControl
                            plugin={plugin}
                            showZoom={true}
                            showFocus={true}
                            showIris={true}
                            showSavePreset={true}
                            currentPtzId={curPreset}
                            currentChannel={curChannel}
                            currentPtzName={curPtzName}
                            stopFlag={stopPtzFastPosFlag}
                            hideFastPostion={true}
                        />
                    </Modal>
                </div>

            </div>
        );
    }
}

export default LangConsumer(bindLifecycle(TrafficIllegalRegionConfigurationPluginComponent));